var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"newsletter col-lg-12"},[_c('div',{staticClass:"news-vessel"},[_c('div',{staticClass:"contents col-lg-11 col-md-11 col-sm-12",staticStyle:{"width":"100%"}},[_c('img',{staticStyle:{"max-width":"90%","margin-bottom":"30px"},attrs:{"src":require("../../../assets/pc_img/club100.jpg"),"alt":""}}),(_vm.swiperList.length)?_c('section',[_c('h3',[_vm._v("CLUB 100 MEMBERS")]),_c('div',{staticClass:"news_box"},[_c('div',{staticClass:"swiper_box"},[_c('swiper',{ref:"mySwiper",staticClass:"swiper",attrs:{"options":_vm.clubSwiperOption}},_vm._l((_vm.swiperList),function(item,index){return _c('swiper-slide',{key:index},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":item.link,"target":"_blank"}},[_c('div',{staticClass:"contents-pics"},[_c('p',{class:item.membership_level == 1
                          ? 'color1'
                          : item.membership_level == 2
                          ? 'color2'
                          : item.membership_level == 3
                          ? 'color3'
                          : 'color4'},[_vm._v(" "+_vm._s(item.membership_level == 1 ? "BRONZE" : item.membership_level == 2 ? "SILVER" : item.membership_level == 3 ? "GOLD" : "PLATINUM")+" ")]),_c('div',{staticClass:"top",class:item.membership_level == 1
                          ? 'level1'
                          : item.membership_level == 2
                          ? 'level2'
                          : item.membership_level == 3
                          ? 'level3'
                          : 'level4'},[_c('img',{attrs:{"src":item.company_logo,"alt":"","data-url":item.company_logo,"name":item.id}})])])])])}),1)],1),_c('div',[_c('div',{staticClass:"swiper-button-prev News_prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next News_next",attrs:{"slot":"button-next"},slot:"button-next"})])])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }