<template>
  <div class="news" v-if="titleSum&&titleSum.is_display&&NewsList.length">
    <img :src="titleSum.image" />
    <div class="new-title">
      <h2>
        {{ titleSum.description }}
        <span>{{ titleSum.name }}</span>
      </h2>
    </div>
    <div class="new-content">
      <div class="new-main">
        <swiper :options="newsSwiperOption" class="swiper-content">
          <swiper-slide v-for="(item, index) in NewsList" :key="index">
            <div class="new-item">
              <div class="img_box" @click="toDetail(item)">
                <img :src="item.cover" alt="" />
              </div>
              <div class="new-text">
                <h3>{{item.title}}</h3>
                <span>{{item.created_at}}</span>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="btns">
          <div class="swiper-button-prev news_prev swiper-button-white" slot="button-prev"></div>
          <div class="swiper-button-next news_next swiper-button-white" slot="button-next"></div>
        </div>
      </div>
    </div>

    <!-- 详情弹框 -->
    <van-popup v-model="show" closeable>
      <div class="news_dialog">
        <h3>{{ newsDetail.title }}</h3>
        <span>{{ newsDetail.created_at }}</span>
        <div class="content">
          <p v-html="newsDetail.introduction"></p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'
  export default {
    data() {
      return {
        newsSwiperOption: {
          loop: false,
          navigation: {
            nextEl: ".swiper-button-next.news_next",
            prevEl: ".swiper-button-prev.news_prev",
          },
          slidesPerView: 2,
          slidesPerColumn: 2,
          spaceBetween: 30,
        },
        show: false,
        newsDetail: {}
      };
    },
    props: {
      NewsList: {
        type: Array
      },
      titleSum: {
        type: Object
      }
    },

    components: {
      Swiper,
      SwiperSlide
    },

    created() { },

    methods: {
      toDetail(item) {
        this.show = true
        this.$http.getWebsiteNewsInfo({
          id: item.id
        }).then((res) => {
          if (res.status == 200) {
            this.newsDetail = res.data.data
          }
        })
      }
    }
  }

</script>
<style lang="scss" scoped>
  ::v-deep .swiper-wrapper .swiper-slide {
    margin: 0 !important;
  }

  .news {
    width: 100%;
    overflow: hidden;
    height: 13rem;
    position: relative;

    >img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.6;
    }

    ::v-deep ul,
    ::v-deep ol {
      padding-left: .35rem !important;
    }

    ::v-deep ul li {
      list-style: disc;
    }

    ::v-deep ol li {
      list-style: decimal;
    }

    ::v-deep li {
      white-space: inherit !important;
    }

    .new-title {
      position: relative;
      white-space: nowrap;

      >h2 {
        font-size: 0.6rem;
        font-weight: 600;
        text-align: left;
        color: rgba(255, 255, 255, .16);
        /* opacity: 0.2; */
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        >span {
          font-size: 0.42rem;
          color: #000;
          text-align: left;
          opacity: 1;
          position: absolute;
          top: 40%;
          left: 4%;
        }
      }
    }

    .new-content {
      margin-top: 0.6rem;

      .new-main {
        padding: 0 0.3rem;
        display: flex;
        flex-wrap: wrap;

        .swiper-content {
          width: 100%;
          height: 10.6rem;
          margin-left: auto;
          margin-right: auto;

          .swiper-slide {
            height: 5rem;
            margin: 0;
          }

          .new-item {
            >.img_box {
              width: 3.3rem;
              height: 3.3rem;
              margin: 0 auto;

              >img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            >.new-text {
              width: 90%;
              margin: 0.2rem auto;

              >h3 {
                font-size: 0.26rem;
                text-align: center;
                font-weight: 600;
                margin-bottom: .1rem;
                overflow: hidden; //溢出隐藏
                text-overflow: ellipsis; //省略号
                display: -webkit-box; //
                -webkit-box-orient: vertical; //设置弹性盒子的子元素的排列方式
                -webkit-line-clamp: 4; //设置显示文本的行数
              }

              >span {
                padding-top: 0.2rem;
              }
            }
          }
        }
      }
    }

    .news_dialog {
      padding: .3rem;
      text-align: left;
      width: 6.8rem;

      h3 {
        font-size: .3rem;
        margin-bottom: .2rem;
      }

      .content {
        margin-top: .3rem;
        font-size: .18rem;
        max-height: 5.8rem;

        &::-webkit-scrollbar-thumb {
          background-color: #ddd !important;
        }

        &::-webkit-scrollbar-track-piece {
          background-color: #fff;
        }
      }
    }
  }
</style>