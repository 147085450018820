<template>
  <div class="banner_container">
    <!-- 轮播图 -->
    <van-swipe class="my_swipe" :autoplay="3000" indicator-color="white" v-if="slideList.length">
      <van-swipe-item v-for="(item, index) in slideList" :key="index">
        <a :href="item.link" target="__blank">
          <img :src="item.image" alt="" v-if="item.model==1"></img>
          <video id="video" class="video" :src="item.image" controls webkit-playsinline playsinline
            x5-video-player-type="h5" v-if="item.model==2"></video>
        </a>
      </van-swipe-item>
    </van-swipe>
    <div class="default" v-else></div>
    <TopBar />
  </div>
</template>

<script>
  import { matchType } from '@/utils/commentFun'
  import TopBar from './TopBar'
  export default {
    components: {
      TopBar
    },
    data() {
      return {
        show: false,
        slideList: [],
        langList: [],
      };
    },

    created() {
      this.getWebsiteCarousel()
    },

    methods: {
      // 获取轮播图
      getWebsiteCarousel() {
        this.$http.getWebsiteCarousel().then((res) => {
          if (res.status == 200) {
            this.slideList = res.data.data
            this.slideList.forEach((item) => {
              if (matchType(item.image) == 'video') {
                this.$set(item, 'model', 2)
              } else if (matchType(item.image) == 'image') {
                this.$set(item, 'model', 1)
              }
            })
          }
        })
      }
    }
  }

</script>
<style scoped lang="less">
  .banner_container {
    position: relative;

    .my_swipe {
      width: 100%;
      height: 5.14rem;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      /deep/ .van-swipe__indicator {
        opacity: 1;
        width: .15rem;
        height: .15rem;
      }

      /deep/ .van-swipe__indicator--active {
        width: .46rem;
        border-radius: .08rem;
      }
    }

    .default {
      width: 100%;
      height: 5.14rem;
      background-image: url(../../../assets/image/banner_1.png);
      background-size: 100% 5.14rem;
    }

    .lang_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: absolute;
      top: .22rem;
      left: 0;
      padding: 0 .3rem;

      .logo {
        width: 1.17rem;
        height: .39rem;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .language_box {
      display: flex;
      align-items: center;

      img {
        width: .46rem;
        height: .46rem;
      }

      .language_content {
        position: relative;
      }

      .language {
        position: absolute;
        width: 2rem;
        right: 0;
        top: .86rem;
        max-height: 3.2rem;
        background: #4266b0;
        border-radius: .2rem;
        padding-top: .3rem;
        padding-left: .3rem;
        overflow-y: auto;

        li {
          font-size: .26rem;
          color: #fff;
          margin-bottom: .24rem;
          text-align: left;
        }

        .text_item {
          color: red;
        }
      }

      .user {
        display: flex;
        justify-items: center;
        margin-left: .36rem;
        color: #fff;

        img {
          width: .4rem;
          height: .37rem;
        }
      }
    }
  }
</style>