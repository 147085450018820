<template>
  <div class="web_config" v-if="titleSum&&titleSum.is_display">
    <img :src="titleSum.image" style="
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            opacity: 0.6;
            left: 0;
          " alt="" />
    <div class="partner-title">
      <h2>
        <p>{{ titleSum.description }}</p> <span>{{ titleSum.name }}</span>
      </h2>
    </div>
    <div class="config_container">
      <van-collapse v-model="activeName" accordion>
        <van-collapse-item title="Mission Vision and Values" name="4" class="mission">
          <div class="pic1_content" v-if="configData.our_brand">
            <h3>{{ configData.our_brand.slogan }}</h3>
            <br />
            <div class="row">
              <h4>Our Mission</h4>
              <p class="text">{{ configData.our_brand.mission }}</p>
            </div>
            <div class="row">
              <h4>Our Vision</h4>
              <p class="text">{{ configData.our_brand.vision }}</p>
            </div>
            <div class="row row-s">
              <h4>Our Creed</h4>
              <p class="text" v-html="configData.our_brand.creed"></p>
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item title="History" name="3" class="history">
          <div class="pic2_content" v-if="configData.our_brand">
            <div v-html="configData.our_brand.history"></div>
          </div>
        </van-collapse-item>
        <van-collapse-item title="Global Team" name="2" class="global_eam">
          <div class="pic3_content" v-if="configData.global_team">
            <div class="title">
              <p>{{ configData.global_team.introduction }}</p>
            </div>
            <div class="board-vessel" v-if="configData.global_team.bod_list.length">
              <h3>Board of Directors</h3>
              <div class="b-content">
                <swiper class="swiper" :options="topSwiperOption" ref="mySwiper">
                  <swiper-slide v-for="(item, index) in configData.global_team.bod_list" :key="index">
                    <div class="board-pics" @click="toDetail(item)">
                      <el-image :src="item.image" alt="" />
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
            </div>
            <div class="board-vessel" v-if="configData.global_team.staff_list.length">
              <h3>World Headquarters</h3>
              <div class="b-content">
                <swiper class="swiper" :options="botSwiperOption" ref="mySwiper">
                  <swiper-slide v-for="(item, index) in configData.global_team.staff_list" :key="index">
                    <div class="board-pics" @click="toDetail(item)">
                      <el-image :src="item.image" alt="" />
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item title="Ambassadors" name="1" class="ambassadors">
          <div class="pic4_content" v-if="configData.ambassadors">
            <div class="title">
              <p>{{ configData.ambassadors.introduction }}</p>
            </div>
            <div class="image_container" v-if="configData.ambassadors.list.length">
              <div class="image_item" v-for="(item, index) in configData.ambassadors.list" :key="index"
                @click="toDetail(item)">
                <img :src="item.image" alt="" />
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <!-- 详情弹出层 -->
    <van-popup v-model="show" closeable>
      <div class="pic3_detail">
        <div class="pic3_top">
          <div class="pic3_img_box">
            <img :src="pic3Detail.image" alt="" />
          </div>
          <div class="pic3_name_box">
            <h3>{{ pic3Detail.name }}</h3>
            <span>{{ pic3Detail.position }}</span>
          </div>
        </div>
        <div class="pic_bottom">
          {{ pic3Detail.introduction }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  export default {
    props: {
      configData: {
        type: Object
      },
      titleSum: {
        type: Object
      }
    },
    data() {
      return {
        activeName: '4',
        topSwiperOption: {
          slidesPerView: 3,
          slidesPerColumn: 1,
          spaceBetween: 10,
          slidesPerGroup: 4,
          speed: 1000,
          autoplay: { // 自动滑动
            delay: 5000, //5秒切换一次
            disableOnInteraction: false
          },
        },
        botSwiperOption: {
          slidesPerView: 3,
          slidesPerColumn: 1,
          spaceBetween: 10,
          slidesPerGroup: 4,
          speed: 1000,
          autoplay: { // 自动滑动
            delay: 5000, //5秒切换一次
            disableOnInteraction: false
          },
        },
        show: false,
        pic3Detail: {}
      };
    },

    components: {
      Swiper,
      SwiperSlide
    },

    created() {

    },

    methods: {
      toDetail(item) {
        this.show = true
        this.pic3Detail = item
      }
    }
  }

</script>
<style scoped lang='less'>
  .web_config {
    position: relative;
    padding-bottom: .6rem;

    .partner-title {
      p {
        color: #3A67B1;
        opacity: .1;
      }
    }

    .config_container {
      margin-top: .38rem;

      ::v-deep .van-cell__right-icon {
        display: none;
      }

      ::v-deep .van-hairline--top-bottom::after,
      .van-hairline-unset--top-bottom::after {
        border-width: 0;
      }

      ::v-deep .van-collapse-item {
        text-align: left;
        padding-left: .3rem;
        padding-right: .3rem;

        span {
          color: #fff;
        }
      }

      ::v-deep .van-cell {
        height: .9rem;
        box-sizing: border-box;
        width: 100%;
        line-height: .9rem;
        font-size: .28rem;
        padding: 0;
        padding-left: .3rem;

        &::after {
          width: 0;
        }
      }

      ::v-deep .van-collapse-item--border::after {
        width: 0;
      }

      .ambassadors {
        ::v-deep .van-cell.van-cell--clickable.van-collapse-item__title {
          background-color: #EDBE38;
        }

        ::v-deep .van-collapse-item__content {
          background-color: #EDBE38;
          padding: 0;
        }

        .pic4_content {
          max-height: 8.7rem;
          overflow-y: auto;
          background-color: #EDBE38;
          padding: 0 0 .3rem .3rem;
          color: #fff;

          .title p {
            font-size: .26rem;
            padding-right: .3rem;
          }
        }

        .image_container {
          margin-top: .3rem;
          display: flex;
          flex-wrap: wrap;

          .image_item {
            width: 1.98rem;
            height: 1.98rem;
            margin-bottom: .18rem;
            margin-right: .18rem;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .global_eam {
        ::v-deep .van-cell.van-cell--clickable.van-collapse-item__title {
          background-color: #56BDA3;
        }

        ::v-deep .van-collapse-item__content {
          background-color: #56BDA3;
          padding: 0;
        }

        .pic3_content {
          max-height: 8.7rem;
          overflow-y: auto;
          background-color: #56BDA3;
          padding: 0 0 .3rem .3rem;
          color: #fff;
          font-size: .26rem;

          .title {
            padding-right: .3rem;
          }
        }

        .board-vessel {
          h3 {
            margin: .3rem 0;
            font-size: .3rem;
            font-weight: 700
          }

          .b-content {
            position: relative;

            .swiper {

              ::v-deep .swiper-button-prev,
              .swiper-button-next {
                position: absolute;
                top: 46%;
              }

              .board-pics {
                height: 2.1rem;
                width: 2.1rem;
                cursor: pointer;

                img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }

      .history {
        ::v-deep .van-cell.van-cell--clickable.van-collapse-item__title {
          background-color: #4266B0;
        }

        ::v-deep .van-collapse-item__content {
          background-color: #4266B0;
          padding: 0;
        }

        .pic2_content {
          max-height: 8.7rem;
          overflow-y: auto;
          padding: 0 0.3rem .3rem .3rem;
          font-size: .26rem;
          color: #fff;

          /deep/ h2 {
            font-size: .3rem;
          }
        }
      }

      .mission {
        ::v-deep .van-cell.van-cell--clickable.van-collapse-item__title {
          background-color: #0D0F30;
        }

        ::v-deep .van-collapse-item__content {
          background-color: #0D0F30;
          padding: 0;
        }

        .pic1_content {
          max-height: 8.7rem;
          overflow-y: auto;
          overflow-x: hidden;
          padding: .4rem 0;
          border-top: .01rem solid rgba(255, 255, 255, .2);
          padding-left: .3rem;

          h3 {
            font-size: .3rem;
            font-weight: 700;
          }

          h4 {
            margin-bottom: .2rem;
            font-size: .26rem;
            font-weight: 700;
          }

          ::v-deep p {
            font-size: .26rem;
            display: inline;
          }

          .row {
            padding: 0 .3rem .3rem .3rem;
          }
        }
      }

    }

    .pic3_detail {
      width: 6.8rem;
      padding: .3rem;

      .pic3_top {
        display: flex;
        margin-bottom: .2rem;

        .pic3_img_box {
          width: 1.6rem;
          height: 1.6rem;
          margin-right: 0.2rem;

          >img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .pic3_name_box {
          >h3 {
            font-size: .3rem;
            color: #000000;
            padding-bottom: 0.2rem;
          }

          h2 {
            font-size: .3rem;
          }

          >span {
            font-size: .24rem;
            color: #ccc;
          }
        }
      }

      .pic_bottom {
        max-height: 6.8rem;
        overflow: auto;
        font-size: .18rem;
        text-align: left;
        line-height: .24rem;
        display: -webkit-box;
        /* word-break: break-all; */
        text-overflow: ellipsis;
        white-space: pre-line;
        -webkit-box-orient: vertical;

        &::-webkit-scrollbar-thumb {
          background-color: #ddd !important;
        }

        &::-webkit-scrollbar-track-piece {
          background-color: #fff;
        }
      }
    }
  }
</style>