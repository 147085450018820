<template>
  <div class="mobile" id="mobile">
    <Banner />
    <WebConfig :titleSum="moduleList[0]" :configData="configData" />
    <FourModel :titleSum="moduleList[1]" />
    <New :titleSum="moduleList[3]" :NewsList="NewsList" />
    <Masterclass :titleSum="moduleList[4]" :NewsList="NewsList" />
    <PartnerScroll />
    <Club100 />
    <Referendum />
    <Contact :type="1" />
  </div>
</template>

<script>
  import Banner from './components/Banner'
  import WebConfig from './components/webConfig';
  import Club100 from './components/Club100.vue';
  import PartnerScroll from './components/PartnerScroll.vue'
  import New from './components/News.vue'
  import FourModel from './components/FourModel'
  import Contact from './components/Contact.vue'
  import Masterclass from './components/Masterclass.vue'
  import Referendum from './components/Referendum.vue'
  export default {
    data() {
      return {
        partnersList: [],
        sponsorList: [],
        NewsList: [],
        newTotal: 0,
        moduleList: [],
        configData: this.$store.state.configList,
      };
    },
    components: { Banner, Club100, PartnerScroll, New, Contact, WebConfig, FourModel, Masterclass, Referendum },
    created() {
      this.getWebsitePartnerList()
      this.getWebsiteNewsList();
      this.getWebsiteModuleList();
      window.onresize = function () {
        let now = window.innerWidth
        // console.log('mobile', now);
        if (now >= 770) {
          window.location.reload();
        }
      }
    },

    methods: {
      // partner
      getWebsitePartnerList(per_page, page, num) {
        this.$http.getWebsitePartnerList({
          num,
        }).then((res) => {
          if (res.status == 200) {
            this.partnersList = res.data.data.list;
            this.sponsorList = res.data.data.random_list;
          }
        })

      },

      // New
      getWebsiteNewsList(is_recommend) {
        this.$http.getWebsiteNewsList({
          per_page: 999,
          is_recommend: 0
        }).then((res) => {
          if (res.status === 200) {
            this.NewsList = res.data.data.list
          }
        })
      },

      //模块配置
      getWebsiteModuleList() {
        this.$http.getWebsiteModuleList().then((res) => {
          if (res.status == 200) {
            this.moduleList = res.data.data
          }
        })
      },
    }
  }

</script>
<style lang="scss" scoped>
  #mobile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    /* 公共样式 */
    ::v-deep .btns {
      width: 3.8rem;
      position: absolute;
      bottom: 8%;
      left: 50%;
      transform: translateX(-50%);

      .swiper-button-prev,
      .swiper-button-next {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
        outline: none;
      }

      .swiper-button-prev:after,
      .swiper-button-next:after {
        font-size: 0.4rem;
        font-weight: 400;
      }
    }

    ::v-deep .partner-title {
      >h2 {
        font-size: 0.6rem;
        color: rgba(255, 255, 255, .2);
        font-weight: 600;
        text-align: left;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        p {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        >span {
          font-size: 0.42rem;
          color: #0d0f30;
          font-weight: 600;
          position: absolute;
          top: 42%;
          left: 4%;
          opacity: 1;
        }
      }
    }

    ::v-deep {
      .van-popup__close-icon--top-right {
        top: .32rem;
        right: .32rem;
      }

      .van-popup__close-icon {
        font-size: .44rem;
      }
    }
  }
</style>