<template>
  <div class="masterclass" v-if="titleSum&&titleSum.is_display">
    <div class="partner-title">
      <h2 class="master">{{ titleSum.description }}<span class="master_name">{{ titleSum.name }}</span></h2>
    </div>
    <div class="desc">
      <p>{{titleSum.brief_introduction}}</p>
    </div>

    <div class="master_image">
      <img :src="titleSum.image" alt="" @click="toDetail(titleSum)">
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      titleSum: {
        type: Object
      }
    },
    data() {
      return {
      };
    },

    methods: {
      toDetail(titleSum) {
        window.open(titleSum.link, '_self')
      }
    }
  }

</script>
<style lang="scss" scoped>
  .masterclass {
    position: relative;
    padding-bottom: .6rem;
    background-color: #0d0f30;

    .desc {
      position: relative;
      padding: .3rem .3rem 0.1rem;
      font-size: .26rem;
      color: #fff;
      z-index: 1;
      text-align: left;
    }

    .partner-title {
      .master {
        font-size: .6rem !important;
      }

      .master_name {
        color: #fff !important;
      }
    }

    .master_image {
      margin: .1rem .3rem;

      img {
        width: 100%;
        max-height: 715px;
        cursor: pointer;
      }
    }
  }
</style>