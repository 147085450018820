<template>
  <div class="partner">
    <h3>Our Global Partners</h3>
    <div class="right-content col-lg-4 col-md-4">
      <ul class="right-vessel" ref="rightImgBox">
        <li v-for="(i, x) in partnerslist" :key="x">
          <div class="pics">
            <a href="https://jcipartnerships.com/" target="__blank">
              <img :src="i.image" alt="" />
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      partnerslist: [],
    };
  },
  props: {},
  components: {},

  created() {
    this.getWebsitePartnerList();
    if (this.partnerslist.length > 9) {
      this.circleShowImgBox();
    }
  },

  methods: {
    // 滚动显示sponsor
    circleShowImgBox() {
      let scrollContainer = this.$refs.rightImgBox;
      let top = scrollContainer.scrollTop;
      clearInterval(timeTop);
      const timeTop = setInterval(() => {
        scrollContainer.scrollTop += 5;
        if (scrollContainer.scrollTop == 300) {
          scrollContainer.scrollTop = 0;
        }
      }, 50);
    },
    getWebsitePartnerList(per_page, page, num) {
      this.$http
        .getWebsitePartnerList({
          per_page,
          page,
          num,
        })
        .then((res) => {
          if (res.status == 200) {
            this.partnerslist = res.data.data;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.right-vessel::-webkit-scrollbar {
  width: 0 !important;
}
.partner{
  h3{
    padding-top: 0.5rem;
  }
  .right-content {
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .right-vessel {
      width: 80%;
      height: 6rem;
      overflow: auto;
  
      &::after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
      }
  
      > li {
        width: 33.3333%;
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
        float: left;
  
        .pics {
          width: 1.46rem;
          height: 1.46rem;
          margin: 0 auto;
          background-color: #ffffff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
  
          img {
            display: block;
            width: 1rem;
            height: 1rem;
            object-fit: cover;
          }
        }
      }
    }
  }
}
</style>
