<template>
  <div class="four_model" v-if="titleSum&&titleSum.is_display">
    <img :src="titleSum.image" style="
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0.6;
        left: 0;
      " alt="" />
    <div class="partner-title">
      <h2>{{ titleSum.description }}<span>{{ titleSum.name }}</span></h2>
    </div>
    <div class="desc">
      <p>{{descData[activeTab]}}</p>
      <!-- Clicking on the items below will take you to the login portal for the JVC. -->
    </div>
    <div class="tabs_container">
      <span :class="['tab_item',{'active_tab':activeTab==item.type}]" v-for="(item, index) in tabList" :key="index"
        @click="changeTab(item)">
        {{item.model}}
      </span>
    </div>
    <div class="partner-main">
      <swiper class="swiper-content" ref="mySwiper" :options="swiperOptionModel">
        <swiper-slide v-for="(item, index) in modelList" :key="index">
          <div class="partner-item animated slideInRight" @click="toDetail(item)">
            <div class="img-box">
              <van-image width="2.5rem" height="1.8rem" fit="cover" :src="item.image" />
            </div>
            <div class="main-content">
              <h3>{{item.title}}</h3>
              <p>{{item.time}}</p>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="btns">
        <div class="swiper-button-prev swiper-button-white model-prev" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white model-next" slot="button-next"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'
  export default {
    props: {
      titleSum: {
        type: Object
      }
    },
    data() {
      return {
        swiperOptionModel: {
          loop: false,
          navigation: {
            nextEl: ".swiper-button-next.model-next",
            prevEl: ".swiper-button-prev.model-prev",
          },
          slidesPerView: 1,
          slidesPerColumn: 3,
          spaceBetween: 30,
        },
        tabList: [
          { model: "Events", type: 2 },
          { model: "Meetings", type: 4 },
          { model: "Projects", type: 1 },
          { model: "Skills Development", type: 5 },
        ],
        activeTab: 2,
        modelList: [],
        descData: {
          2: 'JCI hosts four conferences in each region and one world congress, held in a different location every year. These international events are the perfect opportunity to learn from global leaders and connect with individuals across industries. Our events also provide the backdrop to several exciting competitions in public speaking, debate and business development. Join us for a truly exciting experience with evening programs, cultural experiences and plenty of opportunities to grow as a leader, professional and person.',
          4: 'Collaboration and community are key for our organization and our meetings help facilitate that goal. Our National Presidents gather each year to define their plans of action and work together toward our common vision. Our international Board of Directors create policies and make visits to Local and National Organizations during their year of service. JCI meetings are held at every level and are a great way to learn procedure and etiquette.',
          1: 'One of ways JCI members stand out is through completion of strategic projects. This is where we put our words into action, solving problems and partnering with businesses, governments and civil society to stimulate economic growth and develop innovation solutions. A JCI project is focused around providing benefit to both our members and society at large.',
          3: 'A well-roundedleader is constantly evolving. Through events, programs, trainings and projectsJCI provides all the tools necessary for any young entrepreneur to succeed. Ourtrainers come from more than 100 countries and bring a trove of experience tothe table. With topics such as financial management, funding small business andhow to become a better communicator, JCI offers something for anyone looking togain skills and knowledge.'
        },
      };
    },

    components: {
      Swiper,
      SwiperSlide
    },

    created() {
      this._getWebsiteProjectList(2)
    },

    methods: {
      changeTab(item) {
        this.activeTab = item.type
        this.modelList = []
        this.$refs.mySwiper.initSwiper()
        this._getWebsiteProjectList(item.type)
      },

      toDetail(item) {
        window.open(item.herf)
      },

      _getWebsiteProjectList(type) {
        this.$http.getWebsiteProjectList({
          type
        }).then((res) => {
          if (res.status == 200) {
            if (type == 5) return this.modelList = res.data.data.list.data
            this.modelList = res.data.data.list
          }
        })
      }

    }
  }

</script>
<style lang="scss" scoped>
  ::v-deep .swiper-wrapper .swiper-slide {
    margin: 0 !important;
  }

  .four_model {
    position: relative;
    padding-bottom: .7rem;
    background-color: #ededed;

    .partner-main {
      padding: 0.3rem;

      .swiper-content {
        width: 100%;
        height: 6.5rem;
        margin-left: auto;
        margin-right: auto;

        .swiper-slide {
          height: 2rem;
        }

        .partner-item {
          display: flex;
          align-content: center;
          background-color: #fff;
          height: 1.8rem;

          .img-box {
            margin-right: 0.3rem;
          }

          .main-content {
            padding: .3rem 0.2rem 0.3rem 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 2.22rem);

            >h3 {
              font-size: 0.26rem;
              color: #0d0f30;
              margin-bottom: 0.26rem;
              font-weight: 600;
              text-align: left;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              line-height: .3rem;
            }

            >p {
              font-size: 0.22rem;
              color: #0d0f30;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-align: left;
            }
          }
        }
      }
    }

    .tabs_container {
      margin-top: .4rem;
      padding: 0 .3rem;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 1;

      .tab_item {
        padding: 0 .15rem;
        height: .6rem;
        line-height: .6rem;
        color: #fff;
        font-size: .26rem;
        font-weight: 700;
        text-align: center;
      }

      .active_tab {
        background-color: #fff;
        color: #000;
      }
    }

    .desc {
      position: relative;
      padding: .3rem .3rem 0.1rem;
      font-size: .26rem;
      color: #fff;
      z-index: 1;
      text-align: left;
    }
  }
</style>