<template>
  <div class="news">
    <div v-for="(item, ind) of referList" :key="ind" :id="`publicvoting_${item.referendum_id}`">
      <div class="new-title">
        <h2 class="section_name">{{item.section_name}}</h2>
      </div>
      <section style="padding-left: .3rem;">
        <span class="referendum_title">{{ item.title }}</span>
      </section>
      <p v-html="item.introduction" class="introduction_JCI"></p>
      <div class="new-content">
        <div style="text-align: left;padding-left: 0.38rem; padding-top: .2rem;">
          <el-image style="width: 1.8rem;height: .6rem;" :src="item.cover" cover></el-image>
        </div>
        <div class="new-main" v-if="item.users.length">
          <swiper :options="item.newsSwiperOption" class="swiper-content">
            <swiper-slide v-for="(v, i) in item.users" :key="i">
              <div class="contents-pics">
                <div class="top" @click="checkIntro(v)">
                  <van-image :src="v.head_portrait" fit="cover" />
                </div>
                <div class="bom">
                  <div class="title" @click="checkIntro(v)">{{v.user_name}}</div>
                  <div style="text-align: right;padding:0; padding-right: .1rem;" v-if="item.type">
                    <el-button :disabled="v.is_vote=='1'" @click="checkReferendum(v,item)" size="small" round
                      style="color: #fff" :style="v.is_vote=='1' ? 'background:#6e6ead' : 'background:#0d0f30'">
                      {{v.is_vote?'Voted':'Vote'}}
                    </el-button>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="btnGroup">
            <div class="swiper-button-prev swiper-button-white" :class="`news_prev_${ind}`" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white" :class="`news_next_${ind}`" slot="button-next"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 详情弹框 -->
    <van-popup v-model="show" closeable>
      <div class="pic3_detail_votes" ref="scrollRef">
        <div class="pic3_img_box">
          <van-image :src="introDet.head_portrait" class="img" />
          <div class="pic3_name_box">
            <h3>{{ introDet.user_name }}</h3>
            <div class="pic_bottom" v-html="introDet.introduction"></div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'
  export default {
    data() {
      return {
        newsSwiperOption: {
          loop: false,
          navigation: {
            nextEl: ".swiper-button-next.news_next",
            prevEl: ".swiper-button-prev.news_prev",
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false
          },
          slidesPerView: 2,
          slidesPerColumn: 2,
          spaceBetween: 30,
        },
        show: false,
        introDet: {},
        referList: [],

        random: "",
        flag: true
      };
    },
    props: {
    },

    components: {
      Swiper,
      SwiperSlide
    },

    mounted() {
      this.getIP();
      this.getReferendum();
    },

    methods: {
      getIP() {
        this.random = localStorage.getItem("random");
        if (!this.random) {
          this.random = Math.random();
          if (this.random == '0') this.random = Math.random();
          localStorage.setItem("random", this.random)
        }
      },

      checkReferendum(v, item) {
        this.$http.checkReferendum({
          participant_ip: this.random,
          referendum_user_id: v.referendum_user_id
        }).then((res) => {
          if (res.data.status == 200) {
          } else {
            this.$toast(res.data.message)
          }
          this.getReferendum();
        })
      },

      checkIntro(intro) {
        this.introDet = intro;
        this.show = true;
        // 回到顶部
        this.$nextTick(() => {
          this.$refs.scrollRef.scrollTop = 0;
        })
      },

      getReferendum() {
        this.$http.getReferendum({ participant_ip: this.random }).then((res) => {
          if (res.status == 200) {
            this.referList = res.data.data;
            this.referList.forEach((element, i) => {
              let newsSwiperOption = {
                loop: false,
                navigation: {
                  nextEl: `.swiper-button-next.news_next_${i}`,
                  prevEl: `.swiper-button-prev.news_prev_${i}`,
                },
                slidesPerView: 2,
                slidesPerColumn: 2,
                spaceBetween: 30,
              }
              this.$set(element, 'newsSwiperOption', newsSwiperOption)
            });

            let referendum_id = this.$route.query.publicvoting
            if (referendum_id && this.flag) {
              this.flag = false
              setTimeout(() => {
                document.getElementById(referendum_id).scrollIntoView({
                  behavior: "auto"
                });
              }, 0);
            }
          }
        })
      }

    }

  }

</script>
<style lang="scss" scoped>
  ::v-deep .swiper-wrapper .swiper-slide {
    margin: 0 !important;
  }

  .news {
    width: 100%;
    overflow: hidden;
    position: relative;
    text-align: left;

    .section_name {
      color: #4266B0 !important;
    }

    .introduction_JCI {
      padding-left: .35rem;
      margin-top: -0.3rem;
      padding-right: 0.3rem;

      ::v-deep p {
        font-size: 0.26rem;
      }

      ::v-deep span {
        font-size: 0.26rem !important; 
      }
    }

    .referendum_title {
      font-size: 0.42rem;
      color: #0D0F30;
      opacity: 1;
      position: relative;
      top: -0.32rem;
      line-height: 0.42rem;
    }

    >img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.6;
    }

    ::v-deep ul,
    ::v-deep ol {
      padding-left: .35rem !important;
    }

    ::v-deep ul li {
      list-style: disc;
    }

    ::v-deep ol li {
      list-style: decimal;
    }

    ::v-deep li {
      white-space: inherit !important;
    }

    .new-title {
      position: relative;
      text-align: left;

      >h2 {
        font-size: 0.6rem;
        font-weight: 600;
        text-align: left;
        color: rgba(255, 255, 255, .16);
        opacity: 0.2;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .new-content {
      margin-top: 0.2rem;
      position: relative;
      background: #0d0f30;

      ::v-deep .el-button--small,
      .el-button--small.is-round {
        padding: .09rem .15rem;
      }

      ::v-deep .van-image {
        width: 100%;
        height: 100%;
      }

      .new-main {
        position: relative;
        padding: 0 0.3rem;
        display: flex;
        background: #0d0f30;
        flex-wrap: wrap;

        .swiper-content {
          width: 100%;
          height: 10rem;
          margin-left: auto;
          margin-right: auto;

          .swiper-slide {
            height: 5rem;
            margin: 0;
            padding: 0;
          }

          .contents-pics {
            margin: 0.2rem auto 1rem;

            .top {
              width: 3rem;
              height: 3rem;
              margin: 0 auto;
              padding: 0;
              cursor: pointer;
              background-color: #f4f2f2;

              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .bom {
              width: 3rem;
              height: 1.2rem;
              margin: 0 auto;
              background-color: #ffffff;

              .title {
                height: 0.6rem;
                padding: 0 5px;
                text-align: left;
                display: -webkit-box;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: pre-line;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                font-size: 18px;
              }

              button.el-button.el-button--default.is-disabled.is-round {
                background-color: #999 !important;
              }
            }

          }
        }

        .btnGroup {
          width: 7.5rem;
          position: absolute;
          bottom: 51.5%;
          left: 50%;
          z-index: 1;
          transform: translateX(-50%);

          .swiper-button-prev:after,
          .swiper-button-next:after {
            font-size: 0.4rem;
            font-weight: 400;
          }
        }
      }
    }

    .pic3_detail_votes {
      width: 6.8rem;
      max-height: 10.8rem;
      overflow: auto;
      padding: .3rem .6rem;

      &::-webkit-scrollbar-thumb {
        background-color: #ddd !important;
      }

      &::-webkit-scrollbar-track-piece {
        background-color: #fff;
      }

      .pic3_img_box {
        .img {
          float: left;
          width: 1.6rem;
          height: 1.6rem;
          margin-right: 0.2rem;
          display: block;
          object-fit: cover;
        }
      }

      .pic3_name_box {
        text-align: justify;

        >h3 {
          font-size: .3rem;
          color: #000000;
          padding-bottom: 0.2rem;
        }

        h2 {
          font-size: .3rem;
        }

        >span {
          font-size: .24rem;
          color: #ccc;
        }
      }

      .pic_bottom {
        font-size: .18rem;
        text-align: left;
        line-height: .42rem !important;
      }
    }

  }
</style>